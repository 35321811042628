/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// space generation variables
$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45,
  50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // all positions

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .b#{str-slice($side, 0, 1)}-#{$space} {
      border-#{$side}: #{$space}px solid #ccc !important;
    }
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    @each $side2 in $sides {
      .b#{str-slice($side, 0, 1)}#{str-slice($side2, 0, 1)}-#{$space} {
        border-#{$side}-#{$side2}-radius: #{$space}px !important;
      }
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  .fs-#{$space} {
    font-size: #{$space}px !important;
  }
}

@each $space in $spaceamounts {
  .w-#{$space} {
    width: #{$space}#{'%'} !important;
  }

  .h-#{$space} {
    height: #{$space}#{'%'} !important;
  }
}

@each $space in $spaceamounts {
  .b-#{$space} {
    border: #{$space}px solid var(--color-primary) !important;
  }
}

.clickable {
  cursor: pointer;
}

.centerDesktop {
  max-width: 800px;
  margin: auto !important;
}
.centerDesktop-lg {
  max-width: 1200px;
  margin: auto !important;
}

.bgBlue {
  background-color: #073662;
}

.cWhite {
  color: #fff;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexLeft {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flexRight {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.border-radius {
  border-radius: 4px;
}